export function useTeamReport(
  { cached }: { cached: boolean } = { cached: true },
) {
  const { isAdminOrOwner } = useCurrentUser()

  const teamFetchRequest = useData<TeamReport>(
    isAdminOrOwner.value && 'teamDashboard',
    {
      options: { cached },
    },
  )
  const teamReport = computed(() => teamFetchRequest?.data.value)

  return { teamFetchRequest, teamReport }
}
